import React, {Component} from "react";
import "./Ender.css";

class Ender extends Component {
    render() {
        return (
            <div className="ender">
                Designed and Built by Ted Jenks
            </div>
        )
    }
}

export default Ender
